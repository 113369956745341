/* Message Templates */
.template-container {
    .Mui-active, .Mui-active svg {
        color: #28723C !important;
    }
}
.template-form-richtext, .saved-chat-message-form-richtext {
    .template-form-label {
        margin-bottom: 8px;
    }
}

/* Message Templates & Broadcasts */
.template-form, .broadcast, .automation, .provider-notification, .saved-chat-message-form {
    &-label {
        font-weight: bold;
        margin-bottom: 0;
        margin-left: 8px;
    }
    &-richtext {
        .RaLabeled-label {
            display: none;
        }
        .ProseMirror {
            min-height: 122px; // 3 lines
        }
    }
}

.textinput-multiline {
    label {
        display: none;
    }
    .MuiInputBase-multiline { // match style of richtext field
        background-color: #fafafb;
        border: 1px solid rgba(0, 0, 0, 0.23);
        border-radius: 4px;
        min-height: 122px; // match height of richtext
        padding: 0;
        align-items: baseline;
        &:hover {
            background-color: rgba(0, 0, 0, 0.04);
        }
        &:before, &:after {
            content: none;
        }
        textarea {
            padding: 8px;
            min-height: 106px; // full height
            &:focus {
                outline: revert;
            }
        }
    }
}

.textinput-placeholder {
    &.textinput-multiline .MuiInputBase-multiline {
        min-height: 86px;
        textarea {
            min-height: 70px;
        }
    }
    label[data-shrink=false]+.MuiInputBase-formControl {
        textarea::placeholder { // always visible
            opacity: 0.42 !important;
        }
    }
}

/* Broadcasts */
.broadcast {
    fieldset legend {
        height: 0;
        visibility: hidden;
    }
    &-radio-group p {
        display: none;
    }
    &-textarea {
        width: 100%;
        min-height: 100px;
        font-family: "Roboto","Helvetica","Arial",sans-serif;
        resize: none;
        font-size: 1rem;
        padding: 8px;
        background-color: #fafafb;
        border: 1px solid rgba(0, 0, 0, 0.23);
        border-radius: 4px;
        color: light-dark(rgb(84, 84, 84), rgb(170, 170, 170));
        line-height: 1.5;
        &-wrapper {
            margin-top: -16px;
            margin-bottom: 24px;
        }
        p {
            margin: 4px 0;
            min-height: 1rem;
            &:first-child {
                margin-top: 0;
            }
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    &-save-toolbar {
        display: flex;
        justify-content: space-between;
        button {
            font-weight: bold;
        }
    }
    &-schedule {
        &-trigger {
            font-size: 0.875rem !important;
            line-height: 1.75 !important;
            padding: 6px 16px !important;
        }
        &-submit {
            display: none !important;
        }
        &-fields {
            >div {
                width: calc(50% - 8px);
                &:not(:last-child) {
                    margin-right: 16px;
                }
            }
        }
        &-info {
            line-height: 1.5rem;
            margin: 0;
            font-size: 0.95rem;
            max-width: 55%;
            margin-left: auto;
        }
        &-modal {
            button {
                &:first-child {
                    margin-right: auto;
                    span.MuiButton-startIcon {
                        display: none;
                    }
                }
            }
        }
    }
    &-schedule-modal,
    &-confirm-modal {
        .MuiDialogActions-root {
            padding: 0 24px 16px;
        }
        button {
            color: #fff !important;
            background: #1976d2;
            padding: 8px 20px;
            font-weight: bold;
            &:hover {
                background-color: #1565c0;
                box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
                            0px 4px 5px 0px rgba(0, 0, 0, 0.14),
                            0px 1px 10px 0px rgba(0, 0, 0, 0.12)
            }
        }
    }
    &-confirm-modal {
        p {
            margin: 0;
            color: #757575;
        }
        button:last-child {
            margin-left: auto;
        }
        &:not(.confirm-query) {
            button {
                &:first-child {
                    display: none;
                }
                &:last-child {
                    span.MuiButton-startIcon {
                        display: none;
                    }
                }
            }
        }
        &.confirm-query p:not(:last-child) {
            margin-bottom: 16px;
        }
    }
}

.broadcast-create {
    .queryBuilder-wrapper {
        // margin-top: -16px;
        margin-bottom: 8px;
    }
    // for test messages
    .RaCreate-card {
        overflow: unset;
    }
}

.automation-create, .automation-edit, .provider-notification-create, .provider-notification-edit {
    .queryBuilder {
        margin: 8px 0;
    }
}

.automations-template-modal {
    display: flex;
    overflow: auto;
    > div {
        margin: auto !important;
        > div {
            margin: 2rem 0 !important;
        }
        .test-message {
            display: none;
        }
    }
}

.messages-saved-chat-message-modal {
  display: flex;
  overflow: auto;
  > div {
      margin: auto !important;
      width: 100%;
      > div {
          margin: 2rem 0 !important;
      }
      .test-message {
          display: none;
      }
  }
}

// place 'create template' option at top of list
.message-automations-page {
    ul#template-listbox {
        display: flex;
        flex-direction: column;
        li {
            order: 2;
            &:last-child {
                order: 1;
            }
        }
    }
}